import axios from 'axios';
import { getToken } from "@iso/lib/helpers/utility";
import { getLocalStorage } from '@iso/lib/helpers/utility';
// export const isGrapql = true;
// const baseDomain = "http://api.diaryforlife.info";
//const baseDomain = "https://bpecapi.azurewebsites.net/api";
// const baseDomain = 'https://8ebd4fc65372.ngrok.io/api';
// const baseDomain = 'http://anlunliworkspace.ddns.net:8091/api';
//const baseDomain = "https://localhost:44381/api";
// const baseDomain = "https://bpecapi.azurewebsites.net/api/Test/SendMessage";
const authorization_prefix = 'Bearer ';
// export const customHeaders = {
//   // Accept: "application/json",
//   Accept: "Application/json",
//   /* Authorization: authorization_prefix + token || undefined*/
// };
export const baseUrl = `${process.env.REACT_APP_SERVER_DOMAIN}/api`;

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('id_token')}`;

const service = axios.create({
  baseURL: baseUrl,
  headers: { Authorization: `Bearer ${getLocalStorage('id_token')}` },
  timeout: 30 * 1000,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    config.headers.accpet = 'Application/json';
    config.data = config.payload;
    const token = localStorage.getItem('id_token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    console.log(config);
    return config;
  },
  (error) => {
    console.log('abc', error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    return res;
  },
  (error) => {
    console.log('efg', error)
    return Promise.reject(error);
  }
);

export default service;

// export default axios.create({
//   baseUrl,
//   headers: { "Content-Type": "Application/json" },
// });

// export const serializeQuery = (query) => {
//   return Object.keys(query)
//     .map(
//       (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
//     )
//     .join("&");
// };
