import actions from './UserMaintainActions';
import { notification } from 'antd';

// 初始資料
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  currentPage: 1,
  pagesize: 20,
  CountTotal: 0,
  userMaintainList: [],
  userMaintain: {},
};

export default function userMaintainReducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_LIST_SUCCESS:
      console.log('roleMaintainReducer LOAD_LIST_SUCCESS');
      console.log(payload.data);
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
        CountTotal: payload.data.Data.CountTotal,
        userMaintainList: payload.data.Data.Data,
      };

    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: '哦！載入出現問題！',
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.RESET_PWD:
      return {
        ...state,
      };
    case actions.RESET_PWD_SUCCESS:
      if (payload.data.Code !== 200 && payload.data.Code !== 2001) {
        notification.error({
          message: payload.data.Message,
        });
      }
      return {
        ...state,
      };
    case actions.CRUD:
      return {
        ...state,
        userMaintain: payload.data,
      };
    case actions.CRUD_SUCCESS:
      console.log('走這嗎', payload);
      if (payload.data.Code !== 200 && payload.data.Code !== 2001) {
        notification.error({
          message: payload.data.Message,
        });
      }
      return {
        ...state,
        modalActive:
          payload.data.Code === 200 ||
          payload.data.Code === 555 ||
          payload.data.Code === 400
            ? false
            : true,
      };
    default:
      return state;
  }
}
