const DOCUMENT = "Material_";

const actions = {
  /**載入多筆筆資料*/
  LOAD_LIST: DOCUMENT + "LOAD_LIST ",
  LOAD_LIST_SUCCESS: DOCUMENT + "LOAD_LIST_SUCCESS",
  /**取得資料發生錯誤 */
  LOAD_ERROR: DOCUMENT + "LOAD_ERROR",

  /**載入選項*/
  LOAD_OPTION_LIST: DOCUMENT + 'LOAD_OPTION_LIST',

  /**開啟對話筐*/
  TOGGLE_HANDLE_MODAL: DOCUMENT + "TOGGLE_HANDLE_MODAL",

  CRUD: DOCUMENT + "CRUD",
  CRUD_SUCCESS: DOCUMENT + 'CRUD_SUCCESS',
  /**載入LIST資料*/
  loadlist: (data) => {
    return { type: actions.LOAD_LIST, payload: { data } };
  },
  /**載入LIST資料成功*/
  loadlistSuccess: (data) => ({
    type: actions.LOAD_LIST_SUCCESS,
    payload: { data },
  }),
  loadError: (error) => ({
    type: actions.LOAD_ERROR,
    payload: { error },
  }),
  crud: (data) => ({
    type: actions.CRUD,
    payload: { data },
  }),
  crudSuccess: (data) => ({
    type: actions.CRUD_SUCCESS,
    payload: { data },
  }),

  loadRoleOption: (data) => ({
    type: actions.LOAD_OPTION_LIST,
    payload: { data },
  }),

  /**開啟對話筐*/
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL,
    payload: { data },
  }),
};
export default actions;
