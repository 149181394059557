import { all } from 'redux-saga/effects';
import authSagas from '@iso/redux/auth/saga';
import contactSagas from '@iso/redux/contacts/saga';
import invoicesSagas from '@iso/redux/invoice/saga';

import mailSagas from '@iso/redux/mail/saga';
import notesSagas from '@iso/redux/notes/saga';
import todosSagas from '@iso/redux/todos/saga';
import ecommerceSaga from '@iso/redux/ecommerce/saga';
import cardsSagas from '@iso/redux/card/saga';
import chatSagas from '@iso/redux/chat/sagas';
import youtubeSearchSagas from '@iso/redux/youtubeSearch/sagas';
import githubSagas from '@iso/redux/githubSearch/sagas';
import articles from '@iso/redux/articles/sagas';
import roles from '@iso/redux/roles/sagas';
import investors from '@iso/redux/investors/sagas';
import scrumBoardSaga from '@iso/redux/scrumBoard/saga';
import profileSaga from '@iso/redux/profile/saga';
import quizSaga from '@iso/redux/quiz/saga';
import memberSaga from './members/member/memberSagas';
import orderSaga from './orders/orderSagas';
import shippingFeeSaga from './ShippingFee/ShippingFee/ShippingFeeSagas';
import shippingFeeSagaD from './ShippingFee/ShippingFeeD/ShippingFeeDSagas';
import specialHolidaySagas from './SpecialHoliday/SpecialHoliday/SpecialHolidaySagas';
import specialHolidayMainSagas from './SpecialHoliday/SpecialHolidayMain/SpecialHolidayMainSagas';
import roleMaintainSaga from './RoleMaintain/RoleMaintainSagas';
import userMaintainSaga from './UserMaintain/UserMaintainSagas';
import newsSaga from './News/NewsSagas';
import bannerSaga from './Banner/BannerSagas';
import faqSaga from './FAQ/FAQSagas';
import staticPageSaga from './StaticPage/StaticPageSagas';
import noticeSaga from './Notice/NoticeSagas';
import orderConditionSaga from './OrderCondition/OrderConditionSagas';
import systemArgSaga from './SystemArg/SystemArgSagas';
import materialSaga from './Material/MaterialSagas';
import productTypeSaga from './ProductType/ProductTypeSagas';
import productBoxSaga from './ProductBox/ProductBoxSagas';
import productUnitSaga from './ProductUnit/ProductUnitSagas';
import allergenSaga from './Allergen/AllergenSagas';
import meatAndVegSaga from './MeatAndVeg/MeatAndVegSagas';
import langDictionary from './LangDictionary/LangDictionarySagas';
import orderShipDate from './OrderShipDate/OrderShipDateSagas';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    contactSagas(),
    mailSagas(),
    notesSagas(),
    todosSagas(),
    ecommerceSaga(),
    cardsSagas(),
    invoicesSagas(),

    chatSagas(),
    youtubeSearchSagas(),
    githubSagas(),
    articles(),
    roles(),
    investors(),
    scrumBoardSaga(),
    profileSaga(),
    quizSaga(),
    memberSaga(),
    orderSaga(),
    shippingFeeSaga(),
    shippingFeeSagaD(),
    specialHolidaySagas(),
    specialHolidayMainSagas(),
    roleMaintainSaga(),
    userMaintainSaga(),
    newsSaga(),
    bannerSaga(),
    faqSaga(),
    staticPageSaga(),
    noticeSaga(),
    orderConditionSaga(),
    systemArgSaga(),
    materialSaga(),
    productTypeSaga(),
    productBoxSaga(),
    productUnitSaga(),
    allergenSaga(),
    meatAndVegSaga(),
    langDictionary(),
    orderShipDate()
  ]);
}
