import actions from './BannerActions';
import { notification } from 'antd';

// 初始資料
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  currentPage: 1,
  pagesize: 20,
  CountTotal: 0,
  bannerList: [],
  banner: {},
};

export default function bannerReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_LIST_SUCCESS:
      console.log('roleMaintainReducer LOAD_LIST_SUCCESS');
      console.log(payload.data);
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
        CountTotal: payload.data.Data.CountTotal,
        bannerList: payload.data.Data.Data,
      };
    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: '哦！載入出現問題！',
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.CRUD:
      return {
        ...state,
        banner: payload.data,
      };
    case actions.CRUD_SUCCESS:
      if (payload.data.Code !== 200 && payload.data.Code !== 2001) {
        notification.error({
          message: payload.data.Message,
        });
      }
      return {
        ...state,
        modalActive:
          payload.data.Code === 200 || payload.data.Code === 555 ? false : true,
      };
    default:
      return state;
  }
}
