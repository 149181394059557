// import { __esModule } from "styled-theme";
import React from 'react';
import Repository, { baseUrl } from './Repository';
import { notification, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const commonRepository = () => ({
  // async getDataList(payload) {
  //   //const savePath = payload.Getdata.Apiurl; //一般處理
  //   // const savePath = "/Page/PageCRUD";  //版位拖拉
  //   const savePath = payload.payload.apiurl;
  //   const reponse = await Repository.post(`${baseUrl}${savePath}`, payload)
  //     .then((response) => {
  //       if (response.data.Code !== 200) {
  //         notification.error({
  //           message: response.data.Message,
  //         });
  //         return;
  //       } else {
  //         return response.data;
  //       }
  //     })
  //     .catch((error) => {
  //       notification.error({
  //         message: error.message,
  //       });
  //       return {
  //         error: JSON.stringify(error),
  //       };
  //     });
  //   return reponse;
  // },
  async getDataById(payload) {
    // const savePath = payload.apiurl;
    const responseObj = await Repository({
      url: `${payload.payload.apiurl}`,
      method: 'get',
    })
      .then((response) => {
        const { Code, Message, Data } = response;
        return {
          Code,
          Message,
          Data,
        };
        // return response
      })
      .catch((error) => {
        notification.error({
          message: error.message,
        });
        return {
          error: JSON.stringify(error),
        };
      });
    const { Code, Message, Data } = responseObj;
    return {
      Code,
      Message,
      Data,
    };
  },
  async getDataList(payload) {
    const savePath = payload.payload.apiurl;
    const responseObj = await Repository({
      url: `${savePath}`,
      method: 'post',
      payload,
    })
      .then((response) => {
        const { Code, Message, Data } = response;
        if (Code === 2001) {
          notification.success({
            message: Message,
          });
        }
        return {
          Code,
          Message,
          Data,
        };
        // return response
      })
      .catch((error) => {
        notification.error({
          message: error.message,
        });
        return {
          error: JSON.stringify(error),
        };
      });
    const { Code, Message, Data } = responseObj;
    return {
      Code,
      Message,
      Data,
    };
  },
  async resetPWD(payload) {
    const savePath = payload.payload.apiurl;
    const responseObj = await Repository({
      url: `${savePath}`,
      method: 'post',
      payload: {
        UserId: payload.payload.UserId,
        OldPassword: payload.payload.OldPassword,
        NewPassWord: payload.payload.NewPassWord,
      },
    })
      .then((response) => {
        const { Code, Message, Data } = response;
        if (Code === 200) {
          notification.success({
            message: Message,
          });
        }
        return {
          Code,
          Message,
          Data,
        };
        // return response
      })
      .catch((error) => {
        notification.error({
          message: error.message,
        });
        return {
          error: JSON.stringify(error),
        };
      });
    const { Code, Message, Data } = responseObj;
    return {
      Code,
      Message,
      Data,
    };
  },
  async getLogin(payload, history) {
    const savePath = payload.payload.apiurl;
    console.log(payload);
    if (payload.payload.VCode !== "" && payload.payload.VCode !== null) {
      const responseObj = await Repository({
        url: `${savePath}`,
        method: 'post',
        payload: {
          Account: payload.payload.Account,
          PassWord: payload.payload.PassWord,
        },
      })
        .then((response) => {
          const { Code, Message, Data } = response;
          const { UserData, islogged, isChangePassword, Token } = Data;
          const { UserId, UserName, treeDatas } = UserData;
          if (Code !== 200) {
            notification.error({
              message: Message,
            });
          } else if (Code === 200) {
            console.log("getLogin");
            console.log(Token);
            sessionStorage.setItem('s_id_token', islogged);
            localStorage.setItem('id_token', Token);
            localStorage.setItem('UserId', UserId);
            localStorage.setItem('UserName', UserName);
            localStorage.setItem('treeDatas', JSON.stringify(treeDatas));
            if (!isChangePassword) {
              confirm({
                title: '更改密碼',
                icon: <ExclamationCircleOutlined />,
                content: '偵測到新帳號未變更密碼, 確定導向更改密碼頁?',
                onOk() {
                  history.push('/dashboard/resetPWD');
                },
                onCancel() { },
                okText: '確認',
                cancelText: '取消',
              });
            }
          }
          return {
            Code,
            Message,
            Data,
          };
          // return response
        })
        .catch((error) => {
          notification.error({
            message: error.Message,
          });
          return {
            error: JSON.stringify(error),
          };
        });
      const { Code, Message, Data } = responseObj;
      return {
        Code,
        Message,
        Data,
      };
    }
    else{
      notification.error({
        message: "請選擇我不是機器人",
      });
    }

  },
});
export default commonRepository();
