import actions from './ShippingFeeDActions';
import { notification } from 'antd';

const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  currentPage: 1,
  pagesize: 20,
  CountTotal: 0,
  shippingFeeDList: [],
  shippingFeeD: {},
};

export default function shippingFeeDReducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
        CountTotal: payload.data.Data.CountTotal,
        shippingFeeDList: payload.data.Data.Data,
      };

    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: '哦！載入出現問題！',
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.CRUD:
      return {
        ...state,
        shippingFeeD: payload.data,
      };
    case actions.CRUD_SUCCESS:
      if (payload.data.Code !== 200 && payload.data.Code !== 2001) {
        notification.error({
          message: payload.data.Message,
        });
      }
      return {
        ...state,
        modalActive:
          payload.data.Code === 200 || payload.data.Code === 555 ? false : true,
      };
    default:
      return state;
  }
}
