const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (data, history) => {
    return {
      type: actions.LOGIN_REQUEST,
      payload: { data, history },
    };
  },
  loginSuccess: (data) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { data },
  }),
  loginError: (error) => ({
    type: actions.LOGIN_ERROR,
    payload: { error },
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
