import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import Auth from '@iso/redux/auth/reducer';
import Mails from '@iso/redux/mail/reducer';
import Calendar from '@iso/redux/calendar/reducer';
import Box from '@iso/redux/box/reducer';
import Notes from '@iso/redux/notes/reducer';
import Todos from '@iso/redux/todos/reducer';
import Contacts from '@iso/redux/contacts/reducer';
import Cards from '@iso/redux/card/reducer';
import Chat from '@iso/redux/chat/reducers';
import Roles from '@iso/redux/roles/reducers';

import DynamicChartComponent from '@iso/redux/dynamicEchart/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Invoices from '@iso/redux/invoice/reducer';

import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';
import YoutubeSearch from '@iso/redux/youtubeSearch/reducers';
import Articles from '@iso/redux/articles/reducers';
import Investors from '@iso/redux/investors/reducers';
import scrumBoard from '@iso/redux/scrumBoard/reducer';
import drawer from '@iso/redux/drawer/reducer';
import modal from '@iso/redux/modal/reducer';
import profile from '@iso/redux/profile/reducer';
import githubSearch from '@iso/redux/githubSearch/reducers';
import quiz from '@iso/redux/quiz/reducer';
import member from '../redux/members/member/memberReducers';
import order from './orders/orderReducers';
import shippingFee from '../redux/ShippingFee/ShippingFee/shippingFeeReducers';
import shippingFeeD from '../redux/ShippingFee/ShippingFeeD/shippingFeeDReducers';
import specialHoliday from './SpecialHoliday/SpecialHoliday/SpecialHolidayReducers';
import specialHolidayMain from './SpecialHoliday/SpecialHolidayMain/SpecialHolidayMainReducers';
import roleMaintain from './RoleMaintain/RoleMaintainReducers';
import userMaintain from './UserMaintain/UserMaintainReducers';
import news from './News/NewsReducers';
import banner from './Banner/BannerReducers';
import faq from './FAQ/FAQReducers';
import staticPage from './StaticPage/StaticPageReducers';
import notice from './Notice/NoticeReducers';
import orderCondition from './OrderCondition/OrderConditionReducers';
import systemArg from './SystemArg/SystemArgReducers';
import material from './Material/MaterialReducers';
import productType from './ProductType/ProductTypeReducers';
import productBox from './ProductBox/ProductBoxReducers';
import productUnit from './ProductUnit/ProductUnitReducers';
import allergen from './Allergen/AllergenReducers';
import meatAndVeg from './MeatAndVeg/MeatAndVegReducers';
import langDictionary from './LangDictionary/LangDictionaryReducers';
import orderShipDate from './OrderShipDate/OrderShipDateReducers';

export default combineReducers({
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher,
  Mails,
  Calendar,
  Box,
  Notes,
  Todos,
  Contacts,
  Cards,
  Chat,
  DynamicChartComponent,
  Ecommerce,
  Invoices,

  YoutubeSearch,
  Articles,
  Roles,
  Investors,
  scrumBoard,
  modal,
  drawer,
  profile,
  githubSearch,
  quiz,
  member,
  order,
  shippingFee,
  shippingFeeD,
  specialHoliday,
  specialHolidayMain,
  roleMaintain,
  userMaintain,
  news,
  banner,
  faq,
  staticPage,
  notice,
  orderCondition,
  systemArg,
  material,
  productType,
  productBox,
  productUnit,
  allergen,
  meatAndVeg,
  langDictionary,
  orderShipDate
});
