import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './ShippingFeeActions';
import commonRepository from '../../../library/Repository/commonRepository';

function* loadFromDB(MyData) {
  try {
    let obj = MyData.payload.data;
    yield put(
      actions.loadlistSuccess(yield call(commonRepository.getDataList, obj))
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

//傳member進來->刪除一筆->取回前10筆
function* crudToDB(payload) {
  console.log(payload.payload);
  try {
    let obj = payload.payload.data;
    yield put(
      actions.crudSuccess(yield call(commonRepository.getDataList, obj))
    );
  } catch (error) {
    console.log(error);
    yield put(actions.loadError(error));
  }
}

/**
 function* saveColumnsToDB(payload) {
  console.log(payload.payload);
  try {
    yield call(commonRepository.getDataList, payload.payload);
  } catch (error) {
    console.log(error);
    // yield put(actions.loadError(error));
  }
}
 */

function* loadOptionFromDB(MyData) {
  console.log('loadOptionFromDB Mydata');
  console.dir(MyData);
  try {
    let obj = MyData.payload.data;
    yield put(
      actions.loadRoleOption(yield call(commonRepository.getDataList, obj))
    );
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

function* loadShipOptionFromDB(MyData) {
  console.log('loadOptionFromDB Mydata');
  console.dir(MyData);
  try {
    let obj = MyData.payload.data;
    yield put(
      actions.loadShipTypeOption(yield call(commonRepository.getDataList, obj))
    );
  } catch (error) {
    yield put(actions.loadError(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.LOAD_SHIP_TYPE_OPTION, loadShipOptionFromDB)]);
  yield all([takeEvery(actions.LOAD_OPTION_LIST, loadOptionFromDB)]);
  yield all([takeEvery(actions.LOAD_LIST, loadFromDB)]);
  yield all([takeEvery(actions.CRUD, crudToDB)]);
}
