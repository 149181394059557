import actions from './MeatAndVegActions';
import { notification } from 'antd';

// 初始資料
const initState = {
  isLoading: false,
  errorMessage: false,
  modalActive: false,
  currentPage: 1,
  pagesize: 20,
  CountTotal: 0,
  meatAndVegList: [],
  meatAndVeg: {},
  meatAndVegOption: [],
};

export default function meatAndVegReducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.LOAD_LIST:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_LIST_SUCCESS:
      console.log('meatAndVegReducer LOAD_LIST_SUCCESS');
      console.log(payload.data);
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
        CountTotal: payload.data.Data.CountTotal,
        meatAndVegList: payload.data.Data.Data,
      };

    case actions.LOAD_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: '哦！載入出現問題！',
      };

    case actions.LOAD_OPTION_LIST:
      return {
        ...state,
        meatAndVegOption: payload.data.Data,
      };

    case actions.TOGGLE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
      };

    case actions.CRUD:
      return {
        ...state,
        meatAndVeg: payload.data,
      };
    case actions.CRUD_SUCCESS:
      if (payload.data.Code !== 200 && payload.data.Code !== 2001) {
        notification.error({
          message: payload.data.Message,
        });
      }
      return {
        ...state,
        modalActive:
          payload.data.Code === 200 || payload.data.Code === 555 ? false : true,
      };
    default:
      return state;
  }
}
