import actions from './actions';
import { getLocalStorage } from '@iso/lib/helpers/utility';


const initState = {
  idToken: getLocalStorage('id_token'),
  router: JSON.parse(getLocalStorage('treeDatas')),
  userName: getLocalStorage('UserName'),
  userId: getLocalStorage('UserId')
};

export default function authReducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOGIN_REQUEST:
      return {
        ...state,
      };
    case actions.LOGIN_SUCCESS:
      console.log('login LOGIN_SUCCESS');
      console.log(payload);
      return {
        ...state,
        idToken: payload.data.Data.Token,
        router: payload.data.Data.UserData.treeDatas,
        userName: payload.data.Data.UserData.UserName,
        userId: payload.data.Data.UserData.UserId,
      };
    case actions.LOGOUT:
      return {
        ...state,
        idToken: false,
        router: [],
        userName: '',
        userId: 0,
      };
    case actions.CHECK_AUTHORIZATION:
      return {
        ...state,
        idToken: getLocalStorage('id_token'),
        router: JSON.parse(getLocalStorage('treeDatas')),
        userName: getLocalStorage('UserName'),
        userId: getLocalStorage('UserId'),
      }
    default:
      return state;
  }
}
