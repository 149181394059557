import zhMessages from "../locales/zh-Hans-TW.json";

const ZhLan = {
  messages: {
    ...zhMessages,
  },
  antd: null,
  locale: "zh-Hans-TW",
};
export default ZhLan;
